import React from "react";
import styled from "styled-components";
import API from "../../ApiService/ApiService";
import image1 from "../../Assets/Images/OneWear/OneWearWhy1.png";
import image2 from "../../Assets/Images/OneWear/OneWearWhy2.png";
import image3 from "../../Assets/Images/OneWear/OneWearWhy3.png";
import '../../Assets/Fonts/fonts.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

const WhyUsSection5 = ({content_all}) => {
  const api = new API();

  const why_us = {
    section_title: content_all?.onewear_blog_main_title,
    whyus_id: [
      {
        id: 1,
        image: content_all?.onewear_blog_images?.[0]?.image,
        card_title: content_all?.onewear_blog_sub_title1,
        card_description: content_all?.onewear_blog_description1,
        date_desc: content_all?.onewear_blog_dt1,
      },
      {
        id: 2,
        image: content_all?.onewear_blog_images?.[1]?.image,
        card_title: content_all?.onewear_blog_sub_title2,
        card_description: content_all?.onewear_blog_description2,
        date_desc: content_all?.onewear_blog_dt2,
      },
      {
        id: 3,
        image: content_all?.onewear_blog_images?.[2]?.image,
        card_title: content_all?.onewear_blog_sub_title3,
        card_description: content_all?.onewear_blog_description3,
        date_desc: content_all?.onewear_blog_dt3,
      },
    ],
  };

  return (
    <WhyUsSectionAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">{why_us?.section_title}</p>
        </div>
        <div className="WhyUs_Section_Main">
          {/* <div className="WhyUs_Section"> */}
          <Swiper
            spaceBetween={10}
            slidesPerView={1} // Show 1 item on mobile and tablet
            // navigation={{ nextEl: '.custom-next', prevEl: '.custom-prev' }} // Custom navigation
            // pagination={{ clickable: true }} 
            breakpoints={{
              767: { slidesPerView: 2 }, // Show 2 items on tablet
              1023: { slidesPerView: 3 }, // Show 3 items on larger screens
            }}            
          >
            {why_us?.whyus_id?.map((item, index) => (
              <SwiperSlide 
              key={index} 
              style={{ 
                width: "100%", 
                // border: "1px solid red", 
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
                }}
              >
              <div key={index} className="WhyUs_Section_Box">
                <div className="WhyUs_Section_Box_Image">
                  <img src={api.rootUrl1 + item?.image} />
                </div>
                <div className="WhyUs_Section_Box_Title">
                  <div>{item?.card_title}</div>
                </div>
                <div className="WhyUs_Section_Box_Description2">
                  <div>{item?.date_desc}</div>
                </div>
                <div className="WhyUs_Section_Box_Description">
                  <div>{item?.card_description}</div>
                </div>                
              </div>
              </SwiperSlide>
            ))}
            </Swiper>
          {/* </div> */}
        </div>
      </div>
    </WhyUsSectionAlign>
  );
};

export default WhyUsSection5;

const WhyUsSectionAlign = styled.div`
  margin-bottom: 80px;
  .home_wrapper {
    width: 100%;
    flex-shrink: 0;
    background: #ffffff;
  }
  .Top_Title {
    text-align: center;
    padding: 30px 0 30px 0;
  }

  .Top_Title .Title {
    color: #000;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Top_Title .Span_Title {
    padding-left: 10px;
    color: #0174be;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .WhyUs_Section_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;    
  }

  .WhyUs_Section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 50px;
  }

  .WhyUs_Section_Box_Image {
    height: 100%;
    width: 100%;
    margin-bottom: 30px;
  }

  .WhyUs_Section_Box_Image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .WhyUs_Section_Box {
    width: 350px;
    height: 100%;
    display: inline-flex;
    flex-direction: column;    
    /* align-items: center; */
    /* justify-content: center; */
    /* height: 200px; */
  }

  .WhyUs_Section_Box_Title {
    /* padding-top: 10px; */

    /* text-align: center; */
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
  }

  .WhyUs_Section_Box_Description {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    color: #000;
    /* text-align: center; */
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    /* font-weight: 300; */
    line-height: normal;
    margin-bottom: 20px;
  }

  .WhyUs_Section_Box_Description2 {
    color: #000;
    /* text-align: center; */
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    /* font-weight: 300; */
    line-height: normal;
    margin-bottom: 10px;
  }

  /* @media screen and (max-width: 1024px) {
    .WhyUs_Section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .Top_Title span::before {
      content: "";
      white-space: pre;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }
  }

  @media screen and (max-width: 767px) {
    .WhyUs_Section {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .Top_Title span::before {
      content: "";
      white-space: pre;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }
  } */

    @media (max-width: 1200px) {
    .home_wrapper {
      padding: 0 60px;
    }

    .Top_Title p {
      font-size: 22px;
    }
  }

  @media (max-width: 991px) {
    .home_wrapper {
      padding: 0 40px;
    }

    .Top_Title {
      width: 100%;
    }

    .Top_Title p {
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    .home_wrapper {
      padding: 0 20px;
    }

    .Top_Title {
      width: 100%;
    }

    .Top_Title p {
      font-size: 18px;
    }
  }

  @media (max-width: 540px) {
    .home_wrapper {
      padding: 0 10px;
    }

    .Top_Title {
      width: 100%;
    }

    .Top_Title p {
      font-size: 16px;
    }
  }

  @media (max-width: 360px) {
    .home_wrapper {
      padding: 0 5px;
    }

    .Top_Title {
      width: 100%;
    }

    .Top_Title p {
      font-size: 14px;
    }
  }
`;
