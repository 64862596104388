import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import API from "../../../ApiService/ApiService";
import '../../../Assets/Fonts/fonts.css';

const Ab6 = ({content_all}) => {
  const api = new API();

  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="HCFurniture_Main">
          <div className="Left_Section">
            <div className="overlay">
              <p>{content_all?.onewear_about_title}</p>
              <h4>ONEWEAR</h4>
              <p>{content_all?.onewear_about_description}</p>              
              <Button className="custom-button" style={{ borderRadius: 0 }} onClick={() => (window.location.href = `/${content_all?.onewear_about_btn_link}`)}>
                {content_all?.onewear_about_btn_txt}
              </Button>
            </div>
          </div>
          <div className="Right_Section">
            <img src={api?.rootUrl1 + content_all?.onewear_about_banner?.image} alt="banner" />
          </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default Ab6;

const BannerAlign = styled.div`  
  padding-bottom: 80px;
  .home_wrapper {
    display: flex;
    background-color: #2f2f2f;
    padding: 60px 80px;
    width: 100%;
    height: 100%;
  }

  .HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    gap: 20px;
  }

  .HCFurniture_Main .Left_Section,
  .HCFurniture_Main .Right_Section {
    width: 50%;
  }

  .HCFurniture_Main .Right_Section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .HCFurniture_Main .Right_Section img {
    width: 100%;
    height: auto;
    /* max-width: 400px; */
    /* aspect-ratio: 1; */
    object-fit: cover;
  }

  .Left_Section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .overlay p,
  .overlay h4,
  .overlay .custom-button {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 0px;
    color: #ffffff;
    font-family: Inter;
    font-size: 12px;
  }

  h4 {
    margin-bottom: 0px;
    color: #e96027;
    font-family: Montserrat;
  }

  .custom-button {
    border: 1px solid #ffffff !important;
    color: #000 !important;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
  }

  .custom-button:hover {
    background-color: #c23b2c !important;
    color: #000 !important;
    border: 1px solid #c23b2c !important;
  }

  @media (max-width: 768px) {
    .home_wrapper {
      padding: 40px 40px;
    }
    /* .HCFurniture_Main {
      flex-direction: column;
    }

    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }

    .HCFurniture_Main .Right_Section img {
      width: 100%;
      max-width: 300px;
    } */
  }

  @media (max-width: 540px) {

    .home_wrapper {
      padding: 30px 20px;
    }

    .HCFurniture_Main {
      flex-direction: column;
    }

    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }

    .HCFurniture_Main .Right_Section img {
      width: 100%;
      /* max-width: 250px; */
    }
  }
`;
