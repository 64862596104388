import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { company_code, base_url, api_website, api_admin } from "./config";
import crud_service from "./ApiService/CrudService";
import { useDispatch } from "react-redux";
import {
  homeGetAllStart,
  homeGetAllSuccess,
  homeGetAllFail,
} from "./Redux/slices/homeSlice";
import {
  templateorderGetAllStart,
  templateorderGetAllSuccess,
  templateorderGetAllFail,
} from "./Redux/slices/templateOrderArraySlice";
import {
  specificationGetAllStart,
  specificationGetAllSuccess,
  specificationGetAllFail,
} from "./Redux/slices/specificationSlice";
import {
  specificationdetailsGetAllStart,
  specificationdetailsGetAllSuccess,
  specificationdetailsGetAllFail,
} from "./Redux/slices/specificationdetailsSlice";
import {
  aboutusGetAllStart,
  aboutusGetAllSuccess,
  aboutusGetAllFail,
} from "./Redux/slices/aboutus";
import {
  privacypolicyGetAllStart,
  privacypolicyGetAllSuccess,
  privacypolicyGetAllFail,
} from "./Redux/slices/privacypolicy";
import {
  refundpolicyGetAllStart,
  refundpolicyGetAllSuccess,
  refundpolicyGetAllFail,
} from "./Redux/slices/refundpolicy";
import {
  deliverypolicyGetAllStart,
  deliverypolicyGetAllSuccess,
  deliverypolicyGetAllFail,
} from "./Redux/slices/deliverypolicy";
import {
  returnpolicyGetAllStart,
  returnpolicyGetAllSuccess,
  returnpolicyGetAllFail,
} from "./Redux/slices/returnpolicy";
import {
  cancellationpolicyGetAllStart,
  cancellationpolicyGetAllSuccess,
  cancellationpolicyGetAllFail,
} from "./Redux/slices/cancellationpolicy";
import {
  homeCustomGetAllStart,
  homeCustomGetAllSuccess,
  homeCustomGetAllFail,
} from "./Redux/slices/homeCustomSlice";
import {
  socialMediaGetAllStart,
  socialMediaGetAllSuccess,
  socialMediaGetAllFail,
} from "./Redux/slices/socialMedia";
import {
  companyGetAllStart,
  companyGetAllSuccess,
  companyGetAllFail,
} from "./Redux/slices/company";
import {
  bannerGetAllStart,
  bannerGetAllSuccess,
  bannerGetAllFail,
} from "./Redux/slices/banner";
import {
  categoryGetAllStart,
  categoryGetAllSuccess,
  categoryGetAllFail,
} from "./Redux/slices/category";
import {
  CetgoryMenuGetAllStart,
  CetgoryMenuGetAllSuccess,
  CetgoryMenuGetAllFail,
} from "./Redux/slices/categoryMenu";
import {
  productGetAllStart,
  productGetAllSuccess,
  productGetAllFail,
} from "./Redux/slices/product";
import {
  taxGetAllStart,
  taxGetAllSuccess,
  taxGetAllFail,
} from "./Redux/slices/tax";
import {
  getSigleProductStart,
  getSigleProductSuccess,
  getSigleProductFail,
} from "./Redux/slices/singleProduct";
import {
  profileGetAllStart,
  profileGetAllSuccess,
  profileGetAllFail,
} from "./Redux/slices/profile";
import {
  contactusGetAllStart,
  contactusGetAllSuccess,
  contactusGetAllFail,
} from "./Redux/slices/contactus";
import "./Assets/Css/style.css";
import FooterMain from "./Components/Pages/FooterMain";
import HeaderMain from "./Components/Pages/HeaderMain";
import {
  termsAndConditionGetAllStart,
  termsAndConditionGetAllSuccess,
  termsAndConditionGetAllFail,
} from "./Redux/slices/termsAndCondition";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import { Alert, Flex, Spin } from "antd";
import { commonRouter } from "./ApiService/DynamicRouter";
import { useSelector } from "react-redux";
import Blog from "./Components/Pages/Blogs/Blog";
import "./App.css";
// import { delay } from "lodash";

// const delay = ms => new Promise(
//   resolve => setTimeout(resolve, ms)
// );

const App = () => {
  const crud = new crud_service();
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [cardTypes1, setCardTypes1] = useState();
  const [content1, setContent1] = useState();

  //getInitRender
  const getInitRender = async () => {
    await crud.getAll(
      dispatch,
      [homeGetAllStart, homeGetAllSuccess, homeGetAllFail],
      "section",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [aboutusGetAllStart, aboutusGetAllSuccess, aboutusGetAllFail],
      "aboutus",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        privacypolicyGetAllStart,
        privacypolicyGetAllSuccess,
        privacypolicyGetAllFail,
      ],
      "privacypolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        refundpolicyGetAllStart,
        refundpolicyGetAllSuccess,
        refundpolicyGetAllFail,
      ],
      "refundpolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        deliverypolicyGetAllStart,
        deliverypolicyGetAllSuccess,
        deliverypolicyGetAllFail,
      ],
      "deliverypolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        termsAndConditionGetAllStart,
        termsAndConditionGetAllSuccess,
        termsAndConditionGetAllFail,
      ],
      "terms-and-condition",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        returnpolicyGetAllStart,
        returnpolicyGetAllSuccess,
        returnpolicyGetAllFail,
      ],
      "returnpolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        cancellationpolicyGetAllStart,
        cancellationpolicyGetAllSuccess,
        cancellationpolicyGetAllFail,
      ],
      "cancellationpolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [contactusGetAllStart, contactusGetAllSuccess, contactusGetAllFail],
      "contact",
      "",
      (err, res) => {}
    );

    // await crud.getAllTax(
    //   dispatch,
    //   [taxGetAllStart, taxGetAllSuccess, taxGetAllFail],
    //   "tax",
    //   "",
    //   (err, res) => {}
    // );
    await crud.getAll(
      dispatch,
      [profileGetAllStart, profileGetAllSuccess, profileGetAllFail],
      "profile",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [homeCustomGetAllStart, homeCustomGetAllSuccess, homeCustomGetAllFail],
      "templatecustom",
      "",
      (err, res) => {}
    );
    await crud.getCommonAll1(
      dispatch,
      [templateorderGetAllStart, templateorderGetAllSuccess, templateorderGetAllFail],
      "cms",
      { companyCode: company_code },
      (err, res) => {}
    );
    await crud.getCommonAll2(
      dispatch,
      [specificationGetAllStart, specificationGetAllSuccess, specificationGetAllFail],
      "specification",
      { company_code: company_code },
      (err, res) => {}
    );
    await crud.getCommonAll2(
      dispatch,
      [specificationdetailsGetAllStart, specificationdetailsGetAllSuccess, specificationdetailsGetAllFail],
      "specificationdetails",
      { company_code: company_code },
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [socialMediaGetAllStart, socialMediaGetAllSuccess, socialMediaGetAllFail],
      "socialmedia",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [companyGetAllStart, companyGetAllSuccess, companyGetAllFail],
      "company",
      "",
      (err, res) => {
      }
    );
    await crud.getAll(
      dispatch,
      [bannerGetAllStart, bannerGetAllSuccess, bannerGetAllFail],
      "banner",
      "",
      (err, res) => {}
    );

    await crud.getAll(
      dispatch,
      [categoryGetAllStart, categoryGetAllSuccess, categoryGetAllFail],
      "category",
      "",
      (err, res) => {}
    );
    await crud.getAllCategory(
      dispatch,
      [CetgoryMenuGetAllStart, CetgoryMenuGetAllSuccess, CetgoryMenuGetAllFail],
      "category",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [productGetAllStart, productGetAllSuccess, productGetAllFail],
      "products",
      {
        page: 1,
        perpage: 10,
      },
      // "",
      (err, res) => {}
    );

    // await delay(1000);
    setIsLoading(false);
  };

  var cardTypes = [];

  const getAllData = async () => {
    await crud.getCommonAll("cms",{ companyCode: company_code },(err, res) => {
      // console.log("res===????",res?.data?.data[res?.data?.data?.length - 1]?.content)
      const content = res?.data?.company?.[0]?.content;
      // const content = res?.data?.data[res?.data?.data?.length - 1]?.content;
      // setContent1(content);
      // const cardTypes = [];

      if (content) {
        for (const key in content) {
          if (content[key].cardType) {
            cardTypes.push(content[key].cardType);
            setCardTypes1(cardTypes);
          }
        }
      }   
      const removeUniqueIdentifiers = (obj) => {
        const newObj = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = key.split('-')[0];
                newObj[newKey] = obj[key];
            }
        }
        return newObj;
    };
    
    const newContent = removeUniqueIdentifiers(content);
    setContent1(newContent);
      localStorage.setItem("cardTypes", JSON.stringify(cardTypes));
      localStorage.setItem("content", JSON.stringify(newContent));
      // console.log("cardTypes", cardTypes);   
    });
  };

  // console.log("cardTypes1", cardTypes1?.[0]);
  const Wrapper = ({ children }) => {
    const location = useLocation();
    React.useLayoutEffect(() => {
      document.documentElement.scrollTo(500, 0);
    }, [location?.pathname]);
    return children;
  };
  //init
  useEffect(() => {
    getInitRender();
    getAllData();
  }, []);
  const homecustom = useSelector(
    (state) => state?.homeCustomSlice?.homecustom?.data[0]
  );

  // console.log("homecustom from app", homecustom);
  const company = useSelector((state) => state?.company?.company?.data[0]);
  const socialmedia = useSelector(
    (state) => state?.socialMedia?.socialmedia?.data
  );

  return (
    <>
      {isLoading ? (
        <Spin
          tip="Loading"
          size="large"
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 999,
            // color: "#f45b7a",
          }}
        >
          {/* <Router>
          <Wrapper>
            <HeaderMain
              header_type={homecustom?.theme ? homecustom?.theme : ""}
              socialmedia={socialmedia}
              home_custom={homecustom}
              company={company}
            />
            <Routes>
              {commonRouter?.map((e, i) => (
                <>
                  <Route key={i} exact path={e?.path} element={e?.element} />
                </>
              ))}
            </Routes>

            <FooterMain
              footer_type={homecustom?.theme ? homecustom?.theme : ""}
              home_custom={homecustom}
              company={company}
              socialmedia={socialmedia}
            />
          </Wrapper>
        </Router> */}
        </Spin>
      ) : (
        <Router>
          <Wrapper>
          <HeaderWrapper>
            <HeaderMain
              // header_type={"HeaderWithWhiteTransparentBackground"}
              header_type={cardTypes1?.[0]}
              socialmedia={socialmedia}
              home_custom={homecustom}
              company={company}
              content_all={content1}
            />
             </HeaderWrapper>
             <div className="main-content" style={{ marginTop: "58px" }}>
            <Routes>
              {commonRouter?.map((e, i) => (
                <>
                  <Route key={i} exact path={e?.path} element={e?.element} />
                </>
              ))}
            </Routes>
             </div>
            <FooterMain
              // footer_type={"Footer12"}
              footer_type={cardTypes1?.[cardTypes1.length - 1]}
              company={company}
              socialmedia={socialmedia}
              home_custom={homecustom}
              content_all={content1}
            />
          </Wrapper>
        </Router>
      )}
    </>
  );
};

export default App;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
`;
