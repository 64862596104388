import React from "react";
import styled from "styled-components";
import {Button} from "antd";
import API from "../../ApiService/ApiService";

const Banner9 = ({ banner, content_all }) => {

//  console.log("banner9content_all---->", content_all);
 const api = new API();

  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="Banner_Main">
          {/* <div className="Top_Title">
            <p className="Title">no 1 choice of yours</p>
          </div> */}
          <div className="Mid_Title">
            <p className="Title">
            {content_all?.dyken_main_quote}
            </p>
          </div>
          <div className="Bottom_Content">
            <p className="Content">
              {content_all?.dyken_desc}
            </p>
          </div>
          <div className="Button">
            <Button className="Button" onClick={() => window.location.href = content_all?.dyken_banner_link}>
             {banner?.button_text} &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="14"
                viewBox="0 0 41 14"
                fill="none"
              >
                <path
                  d="M1.5957 7.0048H39.0957M39.0957 7.0048C39.0957 7.0048 34.6077 4.05322 33.6957 1.6048M39.0957 7.0048C39.0957 7.0048 35.1281 9.77817 33.6957 12.4048"
                  stroke="#0489D6"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
              </svg>
            </Button>
          </div>
          <div className="Banner_Image">
            <img src={api.rootUrl1 + content_all?.dyken_banner_img?.image} alt="banner" />
          </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default Banner9;

const BannerAlign = styled.div`

 .home_wrapper {
  display: grid;
    place-items: center;
 }

  .Banner_Main {
    padding: 60px 0;
    height: 500px;
    width: 60%;
    display: grid;
    place-items: center;
  }

  .Top_Title .Title {
    color: #adadad;
    text-align: center;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%; /* 31.2px */
    letter-spacing: -0.24px;
    text-transform: capitalize;
  }

  .Mid_Title,
  .Bottom_Content {

 text-align: center;
   
  }

  .Mid_Title .Title {
    width: 100%;
    color: #000;
    text-align: center;
    font-family: Flama Trial;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 83.2px */
    letter-spacing: -0.64px;
    text-transform: capitalize;

  }

  .Content {
    color: #838383;
    text-align: center;
    font-family: Flama Trial;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  .Button {
    color: #0489d6;
    text-align: center;
    font-family: Flama Trial;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    border: none;
    outline: none;
  }

  .Banner_Image {
    position: relative;
    top: -215px;
    left: 45%;
    mix-blend-mode: multiply;
  }

  .Banner_Image img {
    height: 420px;
  }

  
  @media screen and (max-width: 767px) {

    .home_wrapper {
       margin-bottom: 30%;
    }
    
  }





`;
