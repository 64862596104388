import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Button,
  Table,
  Tag,
  Popconfirm,
  Modal,
  Form,
  Image, Typography,
  message,
  Row,
  Col
} from 'antd';
import {
  PlusCircleOutlined, ArrowLeftOutlined
} from "@ant-design/icons";
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import crud_service from '../../../ApiService/CrudService';
import PageUnderConstruction from '../PageUnderConstruction';
import company_code from '../../../config';

const CancellationPolicy = () => {
  const [getpage, setGetpage] = useState([]);                

  //api service 
  const crud = new crud_service();
  const navigate = useNavigate();
  const CancellationPolicy = useSelector((state) => state?.cancellationpolicy?.cancellationpolicy);
  const company = useSelector((state) => state?.company?.company?.data[0]);
  const activeCancellationPolicyFortheCompayCode = CancellationPolicy?.data?.find((x) => x?.company_code == company?.company_code && x?.status == true);
  const htmlContent = activeCancellationPolicyFortheCompayCode?.description
  // console.log("CancellationPolicy", CancellationPolicy?.data);
  // console.log("activeCancellationPolicyFortheCompayCode", activeCancellationPolicyFortheCompayCode);
  return (
    <MainSection>
      {CancellationPolicy?.data?.length ? <div className='about_container'>
        <Row className='about_detail'>
          <Col xs={24} lg={24} sm={24} xxl={24} className='our_mission'>
            <h4 style={{textAlign: "center"}}>{activeCancellationPolicyFortheCompayCode?.title}</h4>
            <div className='about_content' dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Col>
        </Row>
      </div> : <PageUnderConstruction/>}
    </MainSection>
  )
}

export default CancellationPolicy;

const MainSection = styled.section`
/* margin: ${({ company_code }) => (company_code === '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg' ? '60px 0' : '0px 0')}; */
margin-top: 60px;
width: 100%;
  .about_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .about_detail{
      max-width: 1200px;
      line-height: 30px;
      color: black;
    }

    .our_mission{
      width: 100%;
      /* margin: 10px 0px; */
      margin: 50px 0px;
      /* border: 1px solid red; */
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 1024px) {
    .about_content {
      /* border: 1px solid red; */
      margin: 0 auto !important;
      width: 90%;
      margin-top: 50px !important;
      word-wrap: break-word !important;
    }
  }
`;