import {Col, Form, Input, Row, Button, Divider, message} from "antd";
import {EnvironmentOutlined, PhoneOutlined} from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import banner from "../../../Assets/Images/banner.webp";
import API from "../../../ApiService/ApiService";
import company_code from '../../../config';

import { useSelector } from "react-redux";
import PageUnderConstruction from "../PageUnderConstruction";


const Contact1 = ({contactData}) => {
  const api = new API();
  const [form] = Form.useForm();
  const address = contactData?.data[0];
  const [form1] = Form.useForm();

  const company = useSelector((state) => state?.company?.company?.data[0]);
  const activeContactUsFortheCompayCode = contactData?.data?.find((x) => x?.company_code == company?.company_code && x?.status == true);

  // console.log("contactData", contactData?.data[0]);
  // console.log("activeContactUsFortheCompayCode", activeContactUsFortheCompayCode);

  const onFinish = (values) => {
    // console.log("values", values);
    api.addenquiry(values).then((res) => {
      // console.log("res----->", res);
      if (res?.status == 201) {
        message.success(res?.data?.message);  
        form.resetFields();
      } else {
        message.error("Something went wrong");
      }
    });
  };

  return (
    <ContactSection1>
    {/* <div style={{textAlign:"center", fontWeight:"bold", fontSize:"30px"}}>Contact Us</div> */}
    {contactData?.data?.length ? <ContactSection>
      {/* <img src={process.env.REACT_APP_BASE+activeContactUsFortheCompayCode?.image} /> */}
      <Row className="contact_container" gutter={16}>
        <Col span={24} className="about_image_box">
          <img src={process.env.REACT_APP_BASE+activeContactUsFortheCompayCode?.image} />
        </Col>
        <Col sm={24} xs={24} xl={12} md={12} xxl={12} className="Left_Section">
          <h5>Contact Information</h5>
          <div dangerouslySetInnerHTML={{ __html: activeContactUsFortheCompayCode?.description }}>
          </div >
          <Row gutter={24}>
            <Col span={24}>
              <h6>The Office</h6>
              <Row gutter={[16, 16]}>
                <Col span={24} className="icons_align">
                  <div className="icon">
                    <EnvironmentOutlined />
                  </div>
                  <div>
                    <p>
                      {
                        activeContactUsFortheCompayCode?.addressline_1 + "," + activeContactUsFortheCompayCode?.addressline_2 }<br />
                        {activeContactUsFortheCompayCode?.city + ", " +activeContactUsFortheCompayCode?.district + ", "+ activeContactUsFortheCompayCode?.state + "- " + activeContactUsFortheCompayCode?.pincode
                      }
                    </p>
                  </div>
                </Col>
                <Col span={24} className="icons_align">
                  <div className="icon">
                    <PhoneOutlined rotate={90} />
                  </div>
                  <div>
                    <a href={`tel:${activeContactUsFortheCompayCode?.mobile_number}`} style={{color:"black", textDecoration:"none"}}>{activeContactUsFortheCompayCode?.mobile_number}</a>
                  </div>
                </Col>
                <Col span={24} className="icons_align">
                  <div className="icon">
                    <EnvironmentOutlined />
                  </div>
                  <div>
                    <a href={`mailto:${activeContactUsFortheCompayCode?.email}`} style={{color:"black", textDecoration:"none"}}>{activeContactUsFortheCompayCode?.email}</a>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={12}></Col>
          </Row>
        </Col>
        <Divider className="divider" />
        <Col sm={24} xs={24} xl={12} md={12} xxl={12} className="Right_Section">
          <h5>Got Any Questions?</h5>
          <p>Use the form below to get in touch with the sales team</p>
          <Form 
          onFinish={onFinish}
          form={form}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="name" rules={[{ required: true, message: "Please input your Name!" }]}>
                  <Input placeholder="Name*" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email" rules={[{ required: true,pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Please input your Email!" }]}>
                  <Input placeholder="Email*" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="mobile_number" rules={[{ required: true, pattern: /^[6-9]\d{9}$/, message: "Please input your Phone!" }]}>
                  <Input placeholder="Phone*" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="subject" rules={[{ required: true, message: "Please input your Subject!" }]}>
                  <Input placeholder="Subject*" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="message" rules={[{ required: true, message: "Please input your Message!" }]}>
                  <Input.TextArea placeholder="message*" />
                </Form.Item>
              </Col>
              <Col>
                <Button htmlType="submit" className="submit_btn" type="primary">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </ContactSection> : <PageUnderConstruction/>}
    </ContactSection1>
  );
};

export default Contact1;

const ContactSection1 = styled.section`
  /* margin-top: 100px; */
  /* margin-top: 0px !important; */
`

const ContactSection = styled.section`
margin-top: 80px;
  /* margin-top: 0px !important; */
  /* margin: 30px 0; */
  margin: 0px 0 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    width: 100%;
  }
  .contact_container {
    max-width: 1300px;
    /* margin-top: 30px; */
    margin-top: 0px !important;
    /* padding: 20px; */
    padding: 0 20px 20px 20px;
  }

  .about_image_box {
      width: 100%;
      /* height: 350px; */
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f5f5f5;

      img {
        width: 100%;
        /* height: 100%; */
        height: auto;
        aspect-ratio: 3 / 1;
        object-fit: contain;
      }

    }

  .divider {
    display: none;
  }

  .icons_align {
    display: flex;
    .icon {
      margin-right: 10px;
    }
    p {
      margin-bottom: 0px;
    }
  }

  .ant-form {
    width: 100% !important;
  }

  .ant-input:hover {
    border-color: #ed8f09;
  }
  .ant-input:focus {
    border-color: #ed8f09;
  }
  .submit_btn {
    margin-top: 25px;
  }

  @media screen and (max-width: 768px) {
    .contact_container {
      padding: 20px;
    }

    .Right_Section {
      margin: 30px 0 0 0;
    }

    .divider {
      display: block;
    }
  }

  @media screen and (max-width: 992px) {
    .contact_container {
      padding: 20px;
    }
  }
`;
