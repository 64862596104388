import React from "react";
import "../../Assets/Css/style.css";
import styled from "styled-components";
import clientimage from "../../Assets/Images/clientreview.png";
import clientProfile from "../../Assets/Images/clientprofile.png";
import {Rate} from "antd";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import API from "../../ApiService/ApiService";
import "swiper/swiper-bundle.css";
import userDefault from "../../Assets/Images/default_img.png"

SwiperCore.use([Navigation, Pagination, Autoplay]);

const ClientReview1 = ({homecustom, content_all}) => {
  console.log("ClientReview1content_all-->", content_all);
 const api =  new API();
  const Client = [
    {
      image: content_all?.dyken_review_image_1?.image,
      profile: clientProfile,
      name: content_all?.dyken_review_title1,
      content: content_all?.dyken_review_description1,
      star: content_all?.dyken_review_rating1,
    },
    {
      image: content_all?.dyken_review_image_2?.image,
      profile: clientProfile,
      name: content_all?.dyken_review_title2,
      content: content_all?.dyken_review_description2,
      star: content_all?.dyken_review_rating2,
    },
    {
      image: content_all?.dyken_review_image_3?.image,
      profile: clientProfile,
      name: content_all?.dyken_review_title3,
      content: content_all?.dyken_review_description3,
      star: content_all?.dyken_review_rating3,
    },
    {
      image: content_all?.dyken_review_image_4?.image,
      profile: clientProfile,
      name: content_all?.dyken_review_title4,
      content: content_all?.dyken_review_description4,
      star: content_all?.dyken_review_rating4,
    },
  ];

  const calculateSlidesPerView = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth < 767) {
      return 1;
    } else if (windowWidth < 1024) {
      return 2;
    } else {
      return 4;
    }
  };

  return (
    <ClientReviewAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">{content_all?.dyken_title_1}</p>
          <p className="Section_Title">{content_all?.dyken_title_2}</p>
        </div>
        <div className="Bottom_Section_Main">
          <Swiper
            grabCursor
            pagination={{clickable: true}}
            autoplay={{delay: 5000}}
            slidesPerView={
              window.innerWidth < 767 ? 1 : window.innerWidth < 1024 ? 2 : 3
            }
            modules={[EffectCoverflow, Navigation, Pagination, Autoplay]}
            className="mySwiper"
            draggable
          >
            {Client?.map((item, index) => (
              <SwiperSlide key={index} className="slide">
                <div className="Bottom_Section">
                  <div className="Client_Image">
                    <img src={api.rootUrl1 + item?.image} alt="client" />
                  </div>
                  <div className="Bottom_Content">
                    <div className="Client_Profile">
                      {/* <img src={item?.profile ? api.rootUrl1 + item?.profile : userDefault} alt="client-profile" /> */}
                      <img src={item?.profile ? item?.profile : userDefault} alt="client-profile" />
                    </div>
                    <div className="Client_Name">
                      <p>{item?.name}</p>
                    </div>
                    <div className="Client_Content">
                      <p>{item?.content}</p>
                    </div>
                    <div className="Client_Star">
                      <Rate
                        allowHalf
                        disabled
                        className="star"
                        defaultValue={item?.star}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </ClientReviewAlign>
  );
};

export default ClientReview1;

const ClientReviewAlign = styled.div`
  .Top_Title {
    text-align: center;
    margin-bottom: 60px;
  }

  p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .Top_Title .Title {
    color: #505050;
    text-align: center;
    font-family: Flama Trial;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 46.8px */
    letter-spacing: -0.36px;
    text-transform: capitalize;
  }

  .Top_Title .Section_Title {
    color: #000;
    text-align: center;
    font-family: Flama Trial;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 62.4px */
    letter-spacing: -0.48px;
    text-transform: capitalize;
  }

  .Bottom_Section {
    width: 250px;
    height: 330px;
    flex-shrink: 0;
    border-radius: 17.116px 22px;
    background: transparent;
  }

  .Bottom_Section_Main .mySwiper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0 100px;
  }

  .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
  }

  .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
  }

  .slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Bottom_Section_Main .Left,
  .Bottom_Section_Main .Right {
    display: inline-flex;
    padding: 10px;
    align-items: center;
    gap: 14.143px;
    border-radius: 33.944px;
    background: #fff;
    box-shadow: 0px 9.149px 25.414px 0px rgba(0, 0, 0, 0.06);
  }

  .Bottom_Content {
    position: relative;
    top: -200px;
    left: -25px;
    width: 240px;
    border-radius: 10px;
    background: #fafafa;
    backdrop-filter: blur(4.706790924072266px);
    display: grid;
    place-items: center;
    margin: 30px;
    padding-bottom: 10px;
  }

  .Bottom_Content .Client_Name {
    color: #1e1e1e;
    text-align: center;
    font-family: Flama Trial;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .Bottom_Content .Client_Content {
    color: #1e1e1e;
    text-align: center;
    font-family: Flama Trial;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .Bottom_Content .Client_Profile {
    position: relative;
    top: -20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.84);
    backdrop-filter: blur(4.706790924072266px);
    img{
      height: 44px;
      width: 44px;
    }
  }

  .Bottom_Content .Client_Star .star {
    display: flex;
    width: 70px;
    height: 30px;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    flex-shrink: 0;
    color: #343839;
  }

  .mySwiper {
    width: 100%; // or a specific width
    height: 100%; // or a specific height
  }

  @media screen and (max-width: 767px) {
    .Bottom_Section_Main {
      display: flex;
      flex-direction: column;
    }
  }
`;
