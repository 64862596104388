import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import '../../Assets/Fonts/fonts.css';

const FollowUs = ({ content_all }) => {
  const api = new API();

  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="HCFurniture_Main">
          <div className="Left_Section">
            {content_all?.onewear_follow_images?.length > 0 && (
              <img
                src={api.rootUrl1 + content_all?.onewear_follow_images?.[0]?.image}
                alt="banner1"
              />
            )}
          </div>
          <div className="middle_Section">
            {content_all?.onewear_follow_images?.length > 1 && (
              <img
                src={api.rootUrl1 + content_all?.onewear_follow_images?.[1]?.image}
                alt="banner2"
              />
            )}
            <div className="overlay">
              <p>FOLLOW US</p>
              <Link
                to={`/${content_all?.onewear_follow_link}`}
                style={{ textDecoration: "none", color: "#E96027" }}
              >
                <h4>{content_all?.onewear_follow_txt}</h4>
              </Link>
            </div>
          </div>
          <div className="Right_Section">
            {content_all?.onewear_follow_images?.length > 2 && (
              <img
                src={api.rootUrl1 + content_all?.onewear_follow_images?.[2]?.image}
                alt="banner3"
              />
            )}
          </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default FollowUs;

const BannerAlign = styled.div`
  background-color: #ECECEC !important;
  .home_wrapper {
    overflow: hidden;
  }
  .HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #ECECEC;
  }

  .HCFurniture_Main .Left_Section,
  .HCFurniture_Main .Right_Section,
  .HCFurniture_Main .middle_Section {
    width: 33.33%;
    height: auto;
  }

  .middle_Section{
    position: relative;
  }

  .HCFurniture_Main img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #e96027;
    text-align: center;
    padding: 10px;
    z-index: 2;
    width: 100%;
  }

  h4 {
    font-family: Inter;
    font-size: 2rem;
  }

  p {
    font-family: Inter;
    font-size: 1rem;
  }

  @media screen and (max-width: 1024px) {
    .HCFurniture_Main {
      flex-direction: row;
    }
    h4 {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    /* .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      display: none;
    } */
    h4 {
      font-size: 1.25rem;
    }
    p {
      font-size: 0.875rem;
    }
  }

  @media screen and (max-width: 540px) {
    h4 {
      font-size: 1rem;
    }
    p {
      font-size: 0.65rem !important;
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: 425px) {
    /* .HCFurniture_Main {
      flex-direction: column;
    } */
    h4 {
      font-size: 0.875rem;
    }
    p {
      font-size: 0.45rem !important;
      margin-bottom: 0px;
    }
  }
`;
