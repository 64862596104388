import React from 'react';
import styled from "styled-components";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate, Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination]); // Initialize navigation and pagination

let user = "";

const FP12 = ({ content_all }) => {
  const getUser = localStorage.getItem("user");
  const api = new API();
  const navigate = useNavigate();

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  const Favorite_Products = [];

  if (content_all && content_all?.onewear_product) {
    content_all?.onewear_product.forEach((item) => {
      if (!Favorite_Products.some((favoriteItem) => favoriteItem?.value === item?.value)) {
        Favorite_Products.push(item);
      }
    });
  }

  return (
    <WhyUsSectionAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <LeftOutlined className="custom-prev" />
          <p className="Title">{content_all?.onewear_product_title}</p>
          <RightOutlined className="custom-next" />
        </div>
        <div className="ViewAll">
          <p onClick={() => (window.location.href = `/products`)}>View All</p>
        </div>
        <div className="WhyUs_Section_Main">
          <Swiper
            spaceBetween={10}
            slidesPerView={1} // Show 1 item on mobile and tablet
            navigation={{ nextEl: '.custom-next', prevEl: '.custom-prev' }} // Custom navigation
            // pagination={{ clickable: true }} 
            breakpoints={{
              767: { slidesPerView: 2 }, // Show 2 items on tablet
              1023: { slidesPerView: 3 }, // Show 3 items on larger screens
            }}
          >
            {Favorite_Products.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="WhyUs_Section_Box">
                  <div className="WhyUs_Section_Box_Image">
                    <Link to={`/product/${item?.value}`}>
                      <img src={api?.rootUrl1 + item?.image} alt={item?.label} />
                    </Link>
                  </div>
                  <div className="WhyUs_Section_Box_Title">{item?.label}</div>
                  <div className="WhyUs_Section_Box_Description">
                    <div
                      onClick={() => navigate(`/product/${item?.value}`)}
                      style={{ cursor: "pointer" }}
                    >
                      Know More
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </WhyUsSectionAlign>
  );
};

export default FP12;

const WhyUsSectionAlign = styled.div`
  margin-bottom: 80px;

  .home_wrapper {
    padding: 0 80px;
  }

  .Top_Title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 20%;
    margin: auto;

    p {
      font-family: Inter;
      font-size: 25px;
      margin-bottom: 0;
      font-weight: 700;
    }

    .custom-prev, .custom-next {
      font-size: 24px;
      cursor: pointer;
      color: #000;
    }
  }

  .ViewAll {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      font-family: Montserrat;
      font-size: 10px;
      text-decoration: underline;
    }
  }

  .WhyUs_Section_Main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .WhyUs_Section_Box {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .WhyUs_Section_Box_Image {
    width: 100%;
    padding-top: 150%; /* 2:3 aspect ratio */
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
    background-color: #ECECEC;
  }

  .WhyUs_Section_Box_Image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }

  .WhyUs_Section_Box_Title {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    text-align: center;
    height: 2.5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .WhyUs_Section_Box_Description {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    height: 2em;
    overflow: hidden;
  }

  @media (max-width: 1200px) {
    .home_wrapper {
      padding: 0 60px;
    }

    .Top_Title p {
      font-size: 22px;
    }
  }

  @media (max-width: 991px) {
    .home_wrapper {
      padding: 0 40px;
    }

    .Top_Title {
      width: 100%;
    }

    .Top_Title p {
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    .home_wrapper {
      padding: 0 20px;
    }

    .Top_Title {
      width: 100%;
    }

    .Top_Title p {
      font-size: 18px;
    }
  }

  @media (max-width: 540px) {
    .home_wrapper {
      padding: 0 10px;
    }

    .Top_Title {
      width: 100%;
    }

    .Top_Title p {
      font-size: 16px;
    }
  }

  @media (max-width: 360px) {
    .home_wrapper {
      padding: 0 5px;
    }

    .Top_Title {
      width: 100%;
    }

    .Top_Title p {
      font-size: 14px;
    }
  }
`;
