import React, {useEffect} from "react";
import styled from "styled-components";
import API from "../../ApiService/ApiService";
import {Link} from "react-router-dom";
import default_image from "../../Assets/Images/default.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import {Button} from "antd";

const Banner7 = ({banner, content_all}) => {
  // console.log("inside component banner--->", content_all);
  const api = new API();

  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="Banner_Main">
          <div className="Banner_Section_Left">
            <Swiper
              direction={"vertical"}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
              navigation={true}
              draggable={true}
              loop={true}
              slidesPerView={1}
              spaceBetween={0}
              onSlideChange={() => console.log("slide change")}
            >
              <SwiperSlide>
                <Link to={`/products`}>
                {/* <Link to={banner?.link ? banner?.link : void 0}> */}
                  <img
                    src={
                      content_all?.banner_img
                        ? api.rootUrl1 + content_all?.banner_img?.image
                        : default_image
                    }
                    alt={content_all?.banner_img ? content_all?.banner_img?.label : ""}
                  />
                  {/* <img
                    src={
                      banner?.image
                        ? api.rootUrl1 + banner?.image
                        : default_image
                    }
                    alt={banner?.banner_head ? banner?.banner_head : ""}
                  /> */}
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="Banner_Section_Right">
            <p className="Banner_Title">
              {content_all?.smile_banner_black_description ? content_all?.smile_banner_black_description : ""}
              {/* {banner?.banner_title ? banner?.banner_title : ""} */}
            </p>
            <div className="Banner_Description">
              <p className="Banner_Text">
                {content_all?.smile_banner_blue_description ? content_all?.smile_banner_blue_description : ""}
                {/* {banner?.banner_description ? banner?.banner_description : ""} */}
              </p>
              <p className="Love_text">
                <svg
                  className="svg_heart"
                  width="102"
                  height="95"
                  viewBox="0 0 102 95"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M59.1454 66.9781C59.385 67.0867 59.6614 67.1187 59.9367 67.0512C64.8985 65.8343 69.1714 64.0135 72.6371 61.6394C76.5338 58.97 79.4542 55.5316 81.3175 51.4198C81.5948 50.8078 81.3237 50.0869 80.7115 49.8095C80.0995 49.5322 79.3785 49.8035 79.1012 50.4155C77.4241 54.1166 74.7866 57.2174 71.2618 59.6321C68.0387 61.8402 64.0333 63.5411 59.357 64.6879C58.7045 64.8479 58.3053 65.5068 58.4653 66.1593C58.558 66.5366 58.817 66.8293 59.1454 66.9781Z"
                    fill="#E0ECF0"
                  />
                  <path
                    d="M14.7067 41.4322C13.9108 34.7871 14.8639 28.4239 17.5397 22.5189C23.1664 10.102 37.8418 4.57574 50.2534 10.2001C55.4893 12.5727 59.6569 16.6104 62.1793 21.702C67.6707 20.2418 73.4542 20.7135 78.6901 23.0861C91.1017 28.7104 96.6217 43.3881 90.995 55.8051C85.7126 67.4621 74.5591 74.4045 62.4673 77.3699C53.6645 79.5286 45.0736 79.5843 39.9717 79.1781C38.4277 79.0551 37.3938 77.5362 37.8413 76.0533C38.1722 74.9566 39.2228 74.2404 40.3644 74.3335C45.036 74.7151 53.0372 74.672 61.3082 72.6436C71.9394 70.0364 81.8997 64.0859 86.5625 53.7963C91.0817 43.8233 86.649 32.0349 76.6814 27.5181C71.9148 25.3581 66.583 25.16 61.6683 26.9602C60.4371 27.4112 59.0346 26.7756 58.562 25.5526C56.6754 20.6704 53.0113 16.792 48.2447 14.6321C38.2771 10.1152 26.491 14.5541 21.9718 24.5271C19.6682 29.6106 18.8493 35.1034 19.5381 40.8531C20.0132 44.8187 21.2003 48.9271 23.0745 53.1019L29.3291 50.7482C30.6815 50.2393 31.9743 51.6195 31.3778 52.9358L26.0995 64.5839C25.7297 65.4 24.7685 65.7616 23.9526 65.3919L12.3045 60.1135C10.9882 59.5171 11.0503 57.6269 12.4027 57.118L18.5123 54.8189C16.5071 50.2874 15.2296 45.7983 14.7067 41.4322Z"
                    fill="#F6A82D"
                  />
                  <path
                    d="M29.3296 50.7485L24.407 52.601L20.1903 61.9063C19.8205 62.7223 18.8593 63.0839 18.0434 62.7142L23.9531 65.3922C24.769 65.7619 25.7303 65.4001 26.1 64.5842L31.3784 52.9361C31.9749 51.6198 30.6822 50.2395 29.3296 50.7485Z"
                    fill="#F6A82D"
                  />
                  <path
                    d="M39.2947 29.2455C39.9068 29.5228 40.6277 29.2515 40.905 28.6395L41.7395 26.7979C42.0169 26.1859 41.7456 25.465 41.1335 25.1876C40.5215 24.9103 39.8006 25.1816 39.5232 25.7936L38.6887 27.6352C38.4114 28.2472 38.6827 28.9681 39.2947 29.2455Z"
                    fill="#FFE589"
                  />
                  <path
                    d="M36.1219 36.248C36.7339 36.5253 37.4548 36.254 37.7322 35.642L38.5667 33.8004C38.844 33.1884 38.5727 32.4675 37.9607 32.1901C37.3486 31.9128 36.6277 32.1841 36.3504 32.7961L35.5159 34.6377C35.2386 35.2497 35.5098 35.9706 36.1219 36.248Z"
                    fill="#FFE589"
                  />
                  <path
                    d="M40.6722 32.98L42.514 33.8146C43.1261 34.0919 43.847 33.8206 44.1243 33.2086C44.4017 32.5966 44.1304 31.8756 43.5183 31.5983L41.6765 30.7637C41.0645 30.4863 40.3435 30.7576 40.0662 31.3697C39.789 31.9818 40.0602 32.7026 40.6722 32.98Z"
                    fill="#FFE589"
                  />
                  <path
                    d="M33.6683 29.8062L35.5101 30.6408C36.1222 30.9182 36.8431 30.6469 37.1204 30.0348C37.3978 29.4228 37.1265 28.7019 36.5144 28.4245L34.6726 27.5899C34.0606 27.3126 33.3396 27.5839 33.0623 28.1959C32.785 28.8079 33.0563 29.5289 33.6683 29.8062Z"
                    fill="#FFE589"
                  />
                </svg>

                {banner?.banner_name}
              </p>
            </div>
            <div className="Link_Button">
              <Button className="Shop_btn" href={`/products`}>
                {/* {banner?.button_text} */}
                Shop Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default Banner7;

const BannerAlign = styled.div`
  .home_wrapper {
    /* max-width: 2000px; */
  }

  a {
    text-decoration: none;
  }

  .Banner_Main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 40px 0 40px;
  }

  .Banner_Section_Right .Banner_Title {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
  }

  .Banner_Text {
    color: #0174be;
    text-align: right;
    font-family: "Bebas Neue", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .Love_text {
    color: #3d418b;
    text-align: right;
    font-family: "Yellowtail";
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }

  .Banner_Description {
    width: 80%;
    display: flex;
    align-items: end;
    justify-content: space-between;
  }

  .Link_Button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Shop_btn {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #f6a82d;
    border-radius: 0 !important;
  }

  .Shop_btn:focus {
    background: #f6a82d;
    color: #fafafa;
  }

  .Shop_btn:hover {
    background: #f6a82d !important;
    color: #fafafa !important;
  }

  .swiper-slide .swiper-slide-active .swiper-slide-next {
    height: auto !important;
  }

  @media screen and (min-width: 768px) and (max-width: 820px) {
    .Banner_Main {
      align-items: flex-start;
    }

    .swiper-slide .swiper-slide-active .swiper-slide-next {
    height: auto !important;
  }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    .Banner_Main {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }    

    .Banner_Section_Right .Banner_Title {
      color: #000;
      font-family: "Poppins", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-align: center;
      padding-top: 20px;
    }

    .Banner_Text {
      color: #0174be;
      text-align: left;
      font-family: "Bebas Neue", sans-serif;
      font-size: 35px;
      font-weight: 400;
      line-height: 100%;
    }

    .Love_text {
      color: #3d418b;
      text-align: left;
      font-family: "Yellowtail";
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }

    .Banner_Description {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 20px;
    }

    .svg_heart {
      width: 70px;
      height: 70px;
    }

    .swiper-slide .swiper-slide-active .swiper-slide-next {
    height: auto !important;
  }

  }

  @media screen and (min-width: 768px) and (max-width: 1000px) {
    .Banner_Section_Left {
      width: 50%;
    }

    .Banner_Section_Right {
      width: 40%;
    }

    .Banner_Section_Right .Banner_Title {
      color: #000;
      font-family: "Poppins", sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-align: center;
      padding-top: 20px;
    }

    .Banner_Text {
      color: #0174be;
      text-align: left;
      font-family: "Bebas Neue", sans-serif;
      font-size: 35px;
      font-weight: 400;
      line-height: 100%;
    }

    .Love_text {
      color: #3d418b;
      text-align: left;
      font-family: "Yellowtail";
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }

    .Banner_Description {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 20px;
    }

    .svg_heart {
      width: 70px;
      height: 70px;
    }

    .swiper-slide .swiper-slide-active .swiper-slide-next {
    height: auto !important;
  }

  }
`;
