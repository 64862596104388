import React from "react";
import styled from "styled-components";
import API from "../../../ApiService/ApiService";
import { Link } from "react-router-dom";

const BannerFurniture = ({homecustom, content_all}) => {
  const api = new API();

  // console.log("BannerFurniturecontent_all---->", content_all);
  return (
    <div className="home_wrapper">
      <div className="BannerSingle">
        <Link to={content_all?.dyken_single_banner_link}>
          <img src={api.rootUrl1 + content_all?.dyken_single_banner?.image} />
        </Link>
      </div>
    </div>
  );
};

export default BannerFurniture;
