import React from "react";
import "../../Assets/Css/style.css";
import styled from "styled-components";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import {Button} from "antd";
import OfferBanner from "../../Assets/Images/GirlsHub Template/Offer_banner.png";
import API from "../../ApiService/ApiService";

const OfferBanner2 = ({offer, content_all}) => {
  // console.log("offer", offer);
  // console.log("content_all==>offer", content_all)

  const api = new API();

  const navigate = useNavigate();

  return (
    <OfferbannerALign>
      <div className="home_wrapper">
        {/* {offer?.banner?.map((item, index) =>( */}
        <Link to={`/${content_all?.offer_banner_img_top_link}`} style={{textDecoration: "none"}}>
          <div 
          className="Offer_Banner_Main" 
          // key={index}
          >
            {/* <div className="OfferTitle"></div>
            <div className="Offer_Coupon"></div>
            <div className="Shop_Button">
            </div> */}
            <img src={api?.rootUrl1 + content_all?.offer_banner_img_top?.image} />
          </div>
        </Link>
        {/* ))} */}
      </div>
    </OfferbannerALign>
  );
};

export default OfferBanner2;

const OfferbannerALign = styled.div`

 .home_wrapper {
   width: 100%;
   padding: 60px 0px;
 }

 .Offer_Banner_Main {
   width: 100%;
   background: #f8f9fa;
 }

 .Offer_Banner_Main img {
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 1;
    object-fit: contain;
 }

 @media screen and (min-width: 992px) and (max-width: 1199px) {
    max-width: 1024px;
  }

  @media screen and (min-width: 912px) and (max-width: 991px) {
    max-width: 912px;
  }

  @media screen and (min-width: 820px) and (max-width: 911px) {
    /* max-width: 820px; */
  }

  @media screen and (min-width: 768px) and (max-width: 819px) {
    max-width: 768px;
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    max-width: 540px;
  }

  @media screen and (min-width: 430px) and (max-width: 539px) {
    max-width: 430px;    
  }

  @media screen and (min-width: 425px) and (max-width: 429px) {
    max-width: 425px;
  }

  @media screen and (min-width: 414px) and (max-width: 424px) {
    max-width: 414px;
  }

  @media screen and (min-width: 412px) and (max-width: 413px) {
    max-width: 412px;
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {
    max-width: 390px;    
  }

  @media screen and (min-width: 375px) and (max-width: 389px) {
    max-width: 375px;
  }

  @media screen and (min-width: 360px) and (max-width: 374px) {
    max-width: 360px;
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {
    /* max-width: 320px; */
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    max-width: 280px;    
  }

`;
